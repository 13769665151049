<template>
  <BeseCombinationPoductPage
    :resetMergeData="resetMergeData"
    :resetTableOption="resetTableOption"
    :url="url"
    v-bind="permissionList"
  />
</template>

<script>
import BeseCombinationPoductPage from '../module/beseCombinationPoductPage'
import { tableOption as resetTableOption, permissionList } from './const'
export default {
  components: {
    BeseCombinationPoductPage
  },
  data() {
    return {
      resetMergeData: {
        orderItems: [
          { asc: false, column: 'create_time' },
          { asc: true, column: 'id' }
        ]
      },
      resetTableOption,
      permissionList,
      url: '/externaladmin/productService/combinedProductPrototype/list'
    }
  }
}
</script>

<style>
</style>
